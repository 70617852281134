import React from 'react';
import PropTypes from 'prop-types';

import { SVG } from '../../components/icons';

import styles from './link.module.scss';

const Link = ({ text, uri, icon: { publicURL: src }, onLink }) => (
	<a href={uri} className={styles.wrapper} onClick={onLink}>
		<SVG path={src} className={styles.icon} />
		<div className={styles.text}>{text}</div>
	</a>
);

Link.defaultProps = {
	uri: null,
	icon: null,
	text: null,
	onLink: () => {}
};

Link.propTypes = {
	uri: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	icon: PropTypes.shape({
		publicURL: PropTypes.string.isRequired
	}).isRequired,
	onLink: PropTypes.func
};

export default Link;

import React from 'react';
import { graphql } from 'gatsby';

import { Page } from '../components/layout';
import { trackEvent } from '../components/google-analytics';
import {
  format as formatPhone,
  toUri as toPhoneUri,
  toClickToChat
} from '../utils/phone';
import { toUri as toEmailUri } from '../utils/email';

import Link from './contact/link';
import styles from './contact.module.scss';

const Contact = ({
  data: {
    markdownRemark: {
      frontmatter: {
        phone_number,
        phone_icon,
        email_address,
        email_icon,
        whatsapp_number,
        whatsapp_icon
      },
      fields: { slug },
      html
    }
  }
}) => {
  const setLocation = href => {
    if (typeof window !== 'undefined') {
      window.location.href = href;
    }
  };

  const onClickPhone = () => {
    trackEvent('phone', 'phonekontaktseite', undefined, 10);
  };

  const onClickEmail = () => {
    trackEvent('mail', 'mailkontaktseite', undefined, 10);
  };

  const onClickWhatsApp = event => {
    event.preventDefault(); // wait and set url after tracking callback

    const {
      currentTarget: { href }
    } = event;

    trackEvent('whatsapp', 'whatsappkontaktseite', undefined, 10, () => {
      setLocation(href);
    });
  };

  return (
    <Page slug={slug} body={html} showContact={false}>
      <div className={styles.wrapper}>
        <div className={styles.item}>
          <Link
            text={formatPhone(phone_number)}
            uri={toPhoneUri(phone_number)}
            onLink={onClickPhone}
            icon={phone_icon}
          />
        </div>
        <div className={styles.item}>
          <Link
            text={email_address}
            uri={toEmailUri(email_address)}
            onLink={onClickEmail}
            icon={email_icon}
          />
        </div>
        <div className={styles.item}>
          <Link
            text={whatsapp_number}
            uri={toClickToChat(whatsapp_number)}
            onLink={onClickWhatsApp}
            icon={whatsapp_icon}
          />
        </div>
      </div>
    </Page>
  );
};

export const contactQuery = graphql`
  query contactQuery($remarkId: String!) {
    markdownRemark(id: { eq: $remarkId }) {
      frontmatter {
        phone_number
        phone_icon {
          publicURL
        }
        email_address
        email_icon {
          publicURL
        }
        whatsapp_number
        whatsapp_icon {
          publicURL
        }
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Contact;
